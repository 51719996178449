// Import necessary React components and modules
import React, { useRef } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Landing from './components/Landing';
import './App.css';
import FeatureBoxes from "./components/FeatureBoxes"; // Import your custom styles
import ContactBox from './components/ContactBox';


// Main App component
const App = () => {
    const contactRef = useRef(null);

  return (
      <div className="App">
        {/* Header component */}
        <Header />
          <Landing contactRef={contactRef} />
        {/* Main content area */}
          <main>
              <FeatureBoxes/>
              <div ref={contactRef}>
                  <ContactBox phoneNumber="+46739747700" emailAddress="ssfordon@outlook.com"/>
              </div>
          </main>

          {/* Footer component */}
        <Footer />
      </div>
  );
}

// Export the App component
export default App;
