// In Landing.js
import React from 'react';
import './Landing.css'; // Import your custom styles

const Landing = ({ contactRef }) => {
    const handleContactButtonClick = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <section className="landing">
            <div className="landing-content">
                <h1 className="landing-title">Välkommen till KS Super Sport Fordon</h1>
                <p>Vi erbjuder hjälp med service, reparation och försäljning av din bil.</p>
                <button onClick={handleContactButtonClick}>Kontakta Oss</button>
            </div>
        </section>
    );
}

    export default Landing;