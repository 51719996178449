// Import necessary React module
import React from 'react';

// Header component
function Header() {
    return (
        <header>
            <div className="logo">KS Super Sport Fordon</div>
        </header>
    );
}

// Export the Header component
export default Header;