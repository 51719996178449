// ContactBox.js
import React from 'react';

const ContactBox = ({ phoneNumber, emailAddress }) => {
    return (
        <div className="contact-box">
            <h3>Kontakta oss</h3>
            <p>För frågor, ring gärna:</p>
            <p className="phone-number">{phoneNumber}</p>
            <p className="contact-info">Email: <a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
            <p className="address">Adress: Brådstupsvägen 9, 129 39 Hägersten</p>
        </div>
    );
};

export default ContactBox;