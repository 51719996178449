// Import necessary React module
import React from 'react';

// Footer component
function Footer() {
    return (
        <footer>
            <p>&copy; 2024 Supersportfordon. All rights reserved.</p>
            {/* Add additional information or links as needed */}
        </footer>
    );
}

// Export the Footer component
export default Footer;
