import React from 'react';

const FeatureBox = ({ title, description, image }) => {
    const iconStyle = {
        width: '100px', // Set your desired icon width
        height: '100px', // Set your desired icon height
        objectFit: 'contain' // This ensures the image scales correctly within the dimensions
    };

    return (
        <div className="feature-box">
            <img src={image} alt={title} style={iconStyle} />
            <h2>{title}</h2>
            <p>{description}</p>
        </div>
    );
};

export default FeatureBox;
