import React from 'react';
import FeatureBox from './FeatureBox';
import serviceImage from './carlift.jpg';
import repairImage from './reparation.jpg';
import towingImage from './towtruck.jpg';

const FeatureBoxes = () => {
    const features = [
        {
            title: 'Service',
            description: 'Bilservice, Oljebyte, Avgassystem, Broms, Stötdämpare',
            image: serviceImage, // Renamed from icon to image
        },
        {
            title: 'Reparation',
            description: 'Vi gör all typ av reparation',
            image: repairImage, // Renamed from icon to image
        },
        {
            title: 'Bärgning av bil',
            description: 'Vid behov',
            image: towingImage, // Renamed from icon to image
        },
        // Add more features as needed
    ];

    return (
        <div className="feature-boxes">
            {features.map((feature, index) => (
                <FeatureBox key={index} {...feature} />
            ))}
        </div>
    );
};

export default FeatureBoxes;
